import Subpage from "../subpage/Subpage";

import modules from "./styles/Resources.module.css";

const Resources = () => {
  return (
    <Subpage
      titleText="Resources"
      backgroundImageUrl="https://storage.googleapis.com/acafamily/images/fm_cover.JPG"
    >
      <div className={modules.Container}>
        <a
          href="https://www.hcafamily.org/ui/msg.html?page=Conf:HCA%20Summer%20Conference"
          target="_blank"
        >
          Summer Conference Messages
        </a>
        <a href="http://www.hcafamily.org" target="_blank">
          Houston Christian Assembly
        </a>
        <a href="http://www.westcoastchristianconference.com" target="_blank">
          West Coast Christian Conference
        </a>
        <a href="http://www.christianstudy.com/hymnary.html" target="_blank">
          圣徒诗歌 Hymnary
        </a>
        <a href="http://www.christiantapeministry.com" target="_blank">
          Christian Tape Ministry
        </a>
      </div>
    </Subpage>
  );
};

export default Resources;
